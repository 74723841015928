/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    @font-face {
        font-family: "champion-htf";
        font-weight: 700;
        text-tansform: uppercase;
        src: url("fonts/champion-htf-bantamweight-webfont.woff2") format('woff2'),
            url("fonts/champion-htf-bantamweight-webfont.woff") format('woff');
    }
    @font-face {
        font-family: "maaxregular";
        src: url("fonts/maax-webfont.woff2") format('woff2'), url("fonts/maax-webfont.woff") format('woff');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'maaxitalic';
        src: url('fonts/maax-italic-webfont.woff2') format('woff2'),
            url('fonts/maax-italic-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: "champion-htf";
        text-transform: uppercase;
        letter-spacing: 0.05rem;
    }

    p,
    ul {
        font-family: "maaxregular";
    }

    p {
        @apply tracking-wide;
    }

    /* button {
        border-color: white;
    } */
}

html {
    background-color: #ebb9f3;
}

body {
    @apply overscroll-none;
}
